import { useContext } from 'react';
import en from '../components/Intl/en.json';
import de from '../components/Intl/de.json';
import GlobalContext from '../context/GlobalContext';

function useTranslate() {
  const gContext = useContext(GlobalContext);
  let lang = null;
  if (gContext.lang === 'de') lang = de;
  else lang = en;
  const translate = (id, returntype = {}) => {
    let returnValue;
    if (returntype?.isArray) {
      returnValue = [...lang[id]];
    } else if (returntype?.isObject) {
      returnValue = { ...lang[id] };
    } else {
      returnValue = lang[id];
    }
    return returnValue;
  };

  return [translate];
}

export default useTranslate;
